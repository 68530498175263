import React from 'react';

const OctettrussSvg = () => {
    return (
        <svg className="octettruss-svg" version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512px" height="512px" viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0,512) scale(0.1,-0.1)">
                <path className="path" d="M898 3615 c-43 -25 -78 -49 -78 -53 0 -4 156 -96 346 -206 347 -199 363 -206 364 -159 0 11 -519 450 -543 458 -7 3 -47 -16 -89 -40z"/>
                <path className="path" d="M1471 2879 c-95 -165 -171 -302 -169 -304 4 -4 372 110 389 120 12 8 419 473 419 479 0 3 -105 6 -232 6 l-233 0 -174 -301z"/>
                <path className="path" d="M2130 3043 c-57 -65 -112 -127 -122 -139 -18 -20 -16 -23 202 -263 120 -133 223 -241 227 -241 11 1 215 529 208 539 -6 10 -391 221 -403 221 -5 0 -55 -53 -112 -117z"/>
                <path className="path" d="M2521 2321 c-51 -132 -92 -244 -89 -251 4 -14 660 -768 668 -769 3 -1 160 268 350 597 189 328 353 612 363 630 l19 32 -609 0 -608 0 -94 -239z"/>
                <path className="path" d="M3852 4147 l-593 -252 -439 -345 c-391 -307 -438 -346 -422 -358 9 -7 24 -11 32 -9 8 2 328 68 711 147 l697 143 308 439 c273 387 337 489 307 488 -4 -1 -275 -114 -601 -253z"/>
                <path className="path" d="M2825 3168 c11 -6 133 -77 270 -156 l250 -144 475 -89 c261 -49 476 -89 478 -89 1 0 2 6 2 13 0 6 -183 117 -406 245 l-406 232 -341 0 c-262 -1 -337 -3 -322 -12z"/>
                <path className="path" d="M2512 1104 c-194 -175 -352 -322 -352 -326 0 -25 78 15 449 229 l410 237 -29 35 c-16 20 -51 60 -77 90 l-49 54 -352 -319z"/>
            </g>
        </svg>
    )
}

export { OctettrussSvg as default }